import { defineStore } from 'pinia'
import { apiPost } from '~/services/api'
import { reachGoal } from '~/services/YandexMetrika/reachGoal'
import { writePersistentLocalStorage } from '~/services/LocalStorage/localStorage'
import { getLocalStorage } from '~/services/LocalStorage/localStorage'

interface AuthPayload {
  phone: string
  code: string
}

interface ApiResponse {
  data: {
    value: {
      token: string
    }
  }
  errors: any
  status: number
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    loading: false,
    loginError: null,
    is_just_created: null,
    ResStatus: 0,
  }),
  actions: {
    async authenticateUser(payload: AuthPayload) {
      try {
        const { data, errors, status } = (await apiPost({
          url: 'api/auth/verify-enter-code',
          body: {
            phone: payload.phone,
            code: payload.code,
          },
        })) as unknown as ApiResponse

        if (data && data.value && data.value.token) {
          localStorage.clear()
          writePersistentLocalStorage('agentum_token', data.value.token)
          this.is_just_created = data.value.is_just_created
          if (this.is_just_created) {
            const newUser = {
              phone: payload.phone,
              date: data.value.user.created_at,
            }
            reachGoal('Новые_пользователи', newUser)
          }
          reachGoal('Общее_число_входов')
          this.authenticated = true
        } else {
          this.loginError = errors
          this.ResStatus = status
          throw new Error('Не удалось выполнить аутентификацию')
        }
      } catch (error: any) {
        console.error(error)
      }
    },
    async logoutUser() {
      try {
        await apiPost({ url: 'api/auth/logout' })
        this.authenticated = false
        localStorage.clear()
      } catch (e) {
        console.log(e)
      }
    },
  },
})
